import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import Facebook from './Facebook'
import Twitter from './Twitter'

/* Will override the defaults applied via gatsby config */
const SEO = ({
  title,
  description,
  image,
  keywords,
  robots,
  htmlAttributes,
  url,
}) => {
  const customMetafields = []

  if (robots) {
    customMetafields.push({
      name: 'robots',
      content: robots,
    })
  }
  if (title) {
    customMetafields.push(
      {
        property: 'og:title',
        content: title,
      },
      {
        name: 'og:url',
        content: url,
      },
      {
        name: 'og:type',
        content: 'website',
      },
      {
        name: 'og:site_name',
        content: 'MDM house',
      }
    )
  }
  if (description) {
    customMetafields.push(
      {
        property: 'og:description',
        content: description,
      },
      {
        name: 'description',
        content: description,
      }
    )
  }
  if (image) {
    customMetafields.push({
      property: 'og:image',
      content: `${url}${image}`,
    })
  }

  if (Array.isArray(keywords)) {
    customMetafields.push({
      name: 'keywords',
      content: keywords.join(', '),
    })
  }

  return (
    <>
      <Helmet
        title={title}
        htmlAttributes={htmlAttributes}
        meta={customMetafields}
      />
      <Facebook
        desc={description}
        image={`${url}${image}`}
        title={title}
        type="website"
        url={url}
        locale="ru"
        name="MDM house"
      />
      <Twitter
        title={title}
        image={`${url}${image}`}
        desc={description}
        username="MDM house"
      />
    </>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  robots: PropTypes.string,
  htmlAttributes: PropTypes.shape({}),
}

export default SEO
